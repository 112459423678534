// THIS FILE IS GENERATED BY 'npm run set-env-variables'

export const environment = {
  production: true,

  // Configuration/Feature-switches:
  envName: '',
  useServiceWorker: true,
  useOffers: true,
  useQandAs: true,
  useQandASearch: true,

  // Configuration of content
  appName: 'Ask Kyrgyz RC',
  appLogoUrl: 'https://www.redcrescent.kg/ru/simai.data/image/favicon/favicon.png',
  mainPageHeader: '...',
  mainPageIntroduction: '...\n \n Тилиңизди тандаңыз:\n Выберите ваш язык:\n Select your language:\n',
  mainPageNotification: '',
  errorHeader: 'Data Unavailable',
  errorMessage: 'Reach out to us at: ',
  errorContactUrl: 'https://www.redcrescent.kg/ru/contact/',
  errorRetry: 'Try again?',

  // Regions:
  regions: 'ky, ru, en,',
  regionsLabels: 'Кыргызча, Русский, English,',
  regionsSheetIds: '1SJepEBCFjhIfRCzS9hNhzBlG6JsT2VydPM711ARwo3o, 1JGq0QlMAEey8PCM_NGTXhQ1gJS563YjGH61h19vEBJo, 1SVSn6MJK7krCVE7ltYG-K5bQl0AUbexP-zj_20W1DNQ,',

  // Third-party tokens:
  // Google Sheets API:
  google_sheets_api_key: 'AIzaSyDmK878al5GRkOQEox9Fn1OfkerUNDD4u0',
  google_sheets_api_url: 'https://sheets.googleapis.com/v4/spreadsheets',

  // Application Insights
  appInsightsConnectionString: '',
};
